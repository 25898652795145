import { useState, useEffect, useCallback, createContext } from 'react';

import { useAxios } from '../hooks/useAxios';

const UserContext = createContext({
  isLoggedIn: false,
  userId: null,
  userFirstName: null,
  userName: null,
  email: null,
  isAdmin: null,
  employeeId: null,
  masterDataPermissions: null,
  hierarchyPermissions: null,
  featurePermissions: null,
  userSettings: null,
  token: null,
  expirationDate: null,
  logoutData: null,
  login: (userId, userFirstName, userName, email, isAdmin, employeeId, masterDataPermissions, hierarchyPermissions, featurePermissions, userSettings,
    token, expirationDate) => {},
  logout: (logoutData) => {},
  replacePersistentUserSettings: (userSettings) => {},
  savePersistentUserSettings: () => {},
  setBusinessArea: (businessAreaId) => {},
  setOutlet: (outletId) => {},
  setDate: (date) => {},
  setJobBucketsFilter: (jobBuckets) => {},
  setLogoutData: (logoutData) => {},
  setPeriod: (periodId) => {},
  setPreshiftTotalsDisplays: (totalIds) => {},
  setPreshiftTotalDisplay: (totalId) => {},
  setSettlementTotalsDisplays: (totalIds) => {},
  setSettlementTotalDisplay: (totalId) => {},
  setTimesheetTotalsDisplays: (totalIds) => {},
  setTimesheetTotalDisplay: (totalId) => {},
  toggleDarkMode: (isDarkMode) => {}
});

let logoutTimerId;

export const UserContextProvider = props => {
  const [userId, setUserId] = useState(null);
  const [userFirstName, setUserFirstName] = useState(null);
  const [userName, setUserName] = useState(null);
  const [email, setEmail] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);
  const [masterDataPermissions, setMasterDataPermissions] = useState(null);
  const [hierarchyPermissions, setHierarchyPermissions] = useState(null);
  const [featurePermissions, setFeaturePermissions] = useState(null);
  const [userSettings, setUserSettings] = useState(null);
  const [token, setToken] = useState(null);
  const [expirationDate, setExpirationDate] = useState(null);
  const [logoutData, setLogoutData] = useState(null);
  const { sendRequest } = useAxios();

  const loginHandler = useCallback((userId, userFirstName, userName, email, isAdmin, employeeId, masterDataPermissions, hierarchyPermissions,
    featurePermissions, userSettings, token, expirationDate) => {
    setUserId(userId);
    setUserFirstName(userFirstName);
    setUserName(userName);
    setEmail(email);
    setIsAdmin(isAdmin);
    setEmployeeId(employeeId);
    setMasterDataPermissions(masterDataPermissions || null);
    setHierarchyPermissions(hierarchyPermissions || null);
    setFeaturePermissions(featurePermissions || null);
    setUserSettings(userSettings || null);
    setToken(token);
    const tokenExpirationDate = expirationDate ?? new Date(new Date().getTime() + 1000 * 3600 * 24);
    setExpirationDate(tokenExpirationDate);
    setLogoutData(null);
    localStorage.setItem(
      'userInfo',
      JSON.stringify({
        userId,
        userFirstName,
        userName,
        email,
        isAdmin,
        employeeId,
        masterDataPermissions,
        hierarchyPermissions,
        featurePermissions,
        userSettings,
        token,
        expirationDate: tokenExpirationDate.toISOString()
      }));
  }, []);
  
  const logoutHandler = useCallback((logoutData) => {
    setUserId(null);
    setUserFirstName(null);
    setUserName(null);
    setEmail(null);
    setIsAdmin(null);
    setEmployeeId(null);
    setMasterDataPermissions(null);
    setHierarchyPermissions(null);
    setFeaturePermissions(null);
    setUserSettings(null);
    setToken(null);
    setExpirationDate(null);
    setLogoutData(logoutData);
    localStorage.removeItem('userInfo');
  }, []);

  const changeBusinessAreaHandler = useCallback((businessAreaId) => {
    setUserSettings(settings => {
      const updatedSettings = {...settings};
      updatedSettings.businessAreaId = businessAreaId;
      return updatedSettings;
    });
  }, []);

  const changeOutletHandler = useCallback((outletId) => {
    setUserSettings(settings => {
      const updatedSettings = {...settings};
      updatedSettings.outletId = outletId;
      return updatedSettings;
    });
  }, []);

  const changeDateHandler = useCallback((date) => {
    setUserSettings(settings => {
      const updatedSettings = {...settings};
      updatedSettings.date = date;
      return updatedSettings;
    });
  }, []);

  const changeJobBucketsFilterHandler = useCallback((jobBuckets) => {
    setUserSettings(settings => {
      const updatedSettings = {...settings};
      updatedSettings.jobBucketsFilter = jobBuckets;
      return updatedSettings;
    });
  }, []);

  const changeLogoutDataHandler = useCallback((logoutData) => {
    setLogoutData(logoutData);
  }, []);
  
  const changePeriodHandler = useCallback((periodId) => {
    setUserSettings(settings => {
      const updatedSettings = {...settings};
      updatedSettings.periodId = periodId;
      return updatedSettings;
    });    
  }, []);

  const toggleDarkModeHandler = useCallback(async (isDarkMode) => {
    setUserSettings(settings => {
      const updatedSettings = {...settings};
      updatedSettings.isDarkMode = isDarkMode;
      return updatedSettings;
    });

    const storedInfo = {
      ...JSON.parse(localStorage.getItem('userInfo'))
    };
    storedInfo.userSettings.isDarkMode = isDarkMode;
    localStorage.setItem('userInfo', JSON.stringify(storedInfo));

    if (token) {
      try {
        const callResponse = await sendRequest(
          '/users/darkMode',
          'PATCH',
          {
            isDarkMode
          },
          { Authorization: `Bearer ${token}` }
        );
        return callResponse;
      } catch (error) { }
    }
  }, [token, sendRequest]);

  const changePreshiftTotalsDisplaysHandler = useCallback((totalIds) => {
    setUserSettings(settings => {
      const updatedSettings = {...settings};
      if (updatedSettings.preshiftTotalsDisplays?.length > 0) {
        const updatedDisplays = [...updatedSettings.preshiftTotalsDisplays.filter(x => totalIds.some(z => z === x.id))];
        const newDisplays = totalIds.filter(x => !updatedDisplays.some(z => z.id === x)).map(x => ({
          id: x,
          isExpanded: true
        }));
        if (newDisplays.length > 0) {
          updatedDisplays.push(...newDisplays);
        }
        updatedSettings.preshiftTotalsDisplays = updatedDisplays;
      } else {
        updatedSettings.preshiftTotalsDisplays = totalIds.map(x => ({
          id: x,
          isExpanded: true
        }));
      }
      return updatedSettings;
    });
  }, []);

  const changePreshiftTotalDisplayHandler = useCallback((totalId) => {
    setUserSettings(settings => {
      const updatedSettings = {...settings};
      const updatedDisplays = [...updatedSettings.preshiftTotalsDisplays];
      const index = updatedDisplays.findIndex(x => x.id === totalId);
      let item = {...updatedDisplays[index]};
      item.isExpanded = !item.isExpanded;
      updatedDisplays[index] = item;
      updatedSettings.preshiftTotalsDisplays = updatedDisplays;
      return updatedSettings;
    });
  }, []);

  const changeSettlementTotalsDisplaysHandler = useCallback((totalIds) => {
    setUserSettings(settings => {
      const updatedSettings = {...settings};
      if (updatedSettings.settlementTotalsDisplays?.length > 0) {
        const updatedDisplays = [...updatedSettings.settlementTotalsDisplays.filter(x => totalIds.some(z => z === x.id))];
        const newDisplays = totalIds.filter(x => !updatedDisplays.some(z => z.id === x)).map(x => ({
          id: x,
          isExpanded: false
        }));
        if (newDisplays.length > 0) {
          updatedDisplays.push(...newDisplays);
        }
        updatedSettings.settlementTotalsDisplays = updatedDisplays;
      } else {
        updatedSettings.settlementTotalsDisplays = totalIds.map(x => ({
          id: x,
          isExpanded: x === 'total'
        }));
      }
      return updatedSettings;
    });
  }, []);

  const changeSettlementTotalDisplayHandler = useCallback((totalId) => {
    setUserSettings(settings => {
      const updatedSettings = {...settings};
      const updatedDisplays = [...updatedSettings.settlementTotalsDisplays];
      const index = updatedDisplays.findIndex(x => x.id === totalId);
      let item = {...updatedDisplays[index]};
      item.isExpanded = !item.isExpanded;
      updatedDisplays[index] = item;
      updatedSettings.settlementTotalsDisplays = updatedDisplays;
      return updatedSettings;
    });
  }, []);

  const changeTimesheetTotalsDisplaysHandler = useCallback((totalIds) => {
    setUserSettings(settings => {
      const updatedSettings = {...settings};
      if (updatedSettings.timesheetTotalsDisplays?.length > 0) {
        const updatedDisplays = [...updatedSettings.timesheetTotalsDisplays.filter(x => totalIds.some(z => z === x.id))];
        const newDisplays = totalIds.filter(x => !updatedDisplays.some(z => z.id === x)).map(x => ({
          id: x,
          isExpanded: false
        }));
        if (newDisplays.length > 0) {
          updatedDisplays.push(...newDisplays);
        }
        updatedSettings.timesheetTotalsDisplays = updatedDisplays;
      } else {
        updatedSettings.timesheetTotalsDisplays = totalIds.map(x => ({
          id: x,
          isExpanded: x === 'total'
        }));
      }
      return updatedSettings;
    });
  }, []);

  const changeTimesheetTotalDisplayHandler = useCallback((totalId) => {
    setUserSettings(settings => {
      const updatedSettings = {...settings};
      const updatedDisplays = [...updatedSettings.timesheetTotalsDisplays];
      const index = updatedDisplays.findIndex(x => x.id === totalId);
      let item = {...updatedDisplays[index]};
      item.isExpanded = !item.isExpanded;
      updatedDisplays[index] = item;
      updatedSettings.timesheetTotalsDisplays = updatedDisplays;
      return updatedSettings;
    });
  }, []);

  const replacePersistentUserSettingsHandler = useCallback(async (userSettings) => {
    const storedInfo = {
      ...JSON.parse(localStorage.getItem('userInfo')),
      userSettings
    };
    localStorage.setItem('userInfo', JSON.stringify(storedInfo));
    setUserSettings(userSettings);
  }, []);

  const savePersistentUserSettingsHandler = useCallback(async () => {
    const storedInfo = {
      ...JSON.parse(localStorage.getItem('userInfo')),
      userSettings
    };
    localStorage.setItem('userInfo', JSON.stringify(storedInfo));
    try {
      const callResponse = await sendRequest(
        '/users/appSettings',
        'PATCH',
        {
          appSettings: userSettings
        },
        { Authorization: `Bearer ${token}` }
      );
      return callResponse.status;
    } catch (error) { return; }
  }, [token, userSettings, sendRequest]);

  useEffect(() => {
    const storedInfo = JSON.parse(localStorage.getItem('userInfo'));
    let actualDate = new Date();
    actualDate.setHours(actualDate.getHours() + 3);
    if (storedInfo && storedInfo.token && new Date(storedInfo.expirationDate) > actualDate) {
      loginHandler(storedInfo.userId, storedInfo.userFirstName, storedInfo.userName, storedInfo.email, storedInfo.isAdmin, storedInfo.employeeId,
        storedInfo.masterDataPermissions, storedInfo.hierarchyPermissions, storedInfo.featurePermissions, storedInfo.userSettings,
        storedInfo.token, new Date(storedInfo.expirationDate));
    }
  }, [loginHandler]);

  useEffect(() => {
    if (token && expirationDate) {
      const timeToLive = expirationDate.getTime() - new Date().getTime();
      logoutTimerId = setTimeout(logoutHandler, timeToLive);
    } else {
      clearTimeout(logoutTimerId);
    }
  }, [token, expirationDate, logoutHandler]);

  return (
    <UserContext.Provider
      value = {{
        isLoggedIn: !!token,
        userId,
        userFirstName,
        userName,
        email,
        isAdmin,
        employeeId,
        masterDataPermissions,
        hierarchyPermissions,
        featurePermissions,
        userSettings,
        token,
        expirationDate,
        logoutData,
        login: loginHandler,
        logout: logoutHandler,
        replacePersistentUserSettings: replacePersistentUserSettingsHandler,
        savePersistentUserSettings: savePersistentUserSettingsHandler,
        setBusinessArea: changeBusinessAreaHandler,
        setOutlet: changeOutletHandler,
        setDate: changeDateHandler,
        setJobBucketsFilter: changeJobBucketsFilterHandler,
        setLogoutData: changeLogoutDataHandler, 
        setPeriod: changePeriodHandler,
        setPreshiftTotalsDisplays: changePreshiftTotalsDisplaysHandler,
        setPreshiftTotalDisplay: changePreshiftTotalDisplayHandler,
        setSettlementTotalsDisplays: changeSettlementTotalsDisplaysHandler,
        setSettlementTotalDisplay: changeSettlementTotalDisplayHandler,
        setTimesheetTotalsDisplays: changeTimesheetTotalsDisplaysHandler,
        setTimesheetTotalDisplay: changeTimesheetTotalDisplayHandler,
        toggleDarkMode: toggleDarkModeHandler
      }}>
        {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;